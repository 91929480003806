import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import "@fontsource/love-light";
import { useLocation, Link } from "react-router-dom";

import LanguageToggle from "../LanguageToggle/LanguageToggle";

const HeaderDiv = styled.div({
  backgroundColor: "#fff",
  height: "7rem",
  display: "flex",
  flexBasis: "100%",
});

const LogoText = styled(Typography)({
  marginLeft: "0.5em",
  marginTop: "-0.3rem",
  fontFamily: "'Love Light', helvetica",
  color: "#025220",
});

const Navs = styled.nav(() => ({
  display: "flex",
  flexBasis: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  boxShadow: "0px 1px 10px 0px #000",
  fontFamily: "'Love Light', helvetica",
}));

const NavLink = styled(Link)((props: any) => ({
  textDecoration: "none",
  fontSize: "3.5rem",
  margin: "1em",
  borderBottom: props.active === "true" ? "2px solid" : "",
  color: "#025220",
}));

const CenteredHeightDiv = styled.div(() => ({
  display: "flex",
  alignItems: "center",
}));

type NavLinksProps = {
  url: string;
};

const NavLinks = (props: NavLinksProps) => (
  <CenteredHeightDiv>
    <CenteredHeightDiv>
      <NavLink
        to="schedule"
        color="inherit"
        active={(props.url.toLowerCase() === "schedule").toString()}
      >
        Schedule
      </NavLink>
    </CenteredHeightDiv>
    <CenteredHeightDiv>
      <NavLink
        to="travel"
        color="inherit"
        active={(props.url.toLowerCase() === "travel").toString()}
      >
        Travel
      </NavLink>
    </CenteredHeightDiv>
    <CenteredHeightDiv>
      <NavLink
        to="qa"
        color="inherit"
        active={(props.url.toLowerCase() === "qa").toString()}
      >
        Q&amp;A
      </NavLink>
    </CenteredHeightDiv>
    <CenteredHeightDiv>
      <NavLink
        to="rsvp"
        color="inherit"
        active={(props.url.toLowerCase() === "rsvp").toString()}
      >
        RSVP
      </NavLink>
    </CenteredHeightDiv>
  </CenteredHeightDiv>
);

export default function Header() {
  const currentUrl = useLocation();
  const url = currentUrl.pathname.split("/").pop() as string;

  return (
    <HeaderDiv>
      <Navs>
        <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
          <LogoText variant="h1">L&nbsp;&nbsp;&D</LogoText>
        </Link>

        <NavLinks url={url} />
        <LanguageToggle />
      </Navs>
    </HeaderDiv>
  );
}
