import LanguageTypography from "../../app/Utils/LanguageTypography";

import styled from "@emotion/styled";

const Heading = styled(LanguageTypography)(() => ({
  textAlign: "center",
  marginBottom: "2rem",
}));

const MiniSection = styled.div({
  marginTop: "1.5rem",
});

const SubHeading = styled(LanguageTypography)(() => ({
  textAlign: "left",
  marginBottom: "1rem",
}));

const BoldedTypography = styled(LanguageTypography)(() => ({
  fontWeight: 600,
}));

export default function QA() {
  return (
    <>
      <Heading
        english="Q&A"
        dutch="TBC"
        component="h2"
        variant="h2"
        textAlign="center"
      ></Heading>
      <MiniSection>
        <LanguageTypography
          variant="h4"
          component="h4"
          english="Accommodation"
          dutch="TBC"
        ></LanguageTypography>
        <LanguageTypography
          english="We will organise your accommodation from Wednesday 19th until Monday 24th October."
          dutch="TBC"
          component="p"
          variant="body2"
          textAlign="justify"
        ></LanguageTypography>
      </MiniSection>

      <MiniSection>
        <LanguageTypography
          variant="h4"
          component="h4"
          english="Registry"
          dutch="TBC"
        ></LanguageTypography>
        <LanguageTypography
          english="Your presence is enough of a present to us! We’ll only be doing one registry and that will be for the Belgian wedding. More details to follow for that."
          dutch="TBC"
          component="p"
          variant="body2"
          textAlign="justify"
        ></LanguageTypography>
      </MiniSection>

      <MiniSection>
        <LanguageTypography
          variant="h4"
          component="h4"
          english="Can I bring a date?"
          dutch="TBC"
        ></LanguageTypography>
        <LanguageTypography
          english="Please check your invite for your +1!"
          dutch="TBC"
          component="p"
          variant="body2"
          textAlign="justify"
        ></LanguageTypography>
      </MiniSection>

      <MiniSection>
        <LanguageTypography
          variant="h4"
          component="h4"
          english="Are kids welcome?"
          dutch="TBC"
        ></LanguageTypography>
        <LanguageTypography
          english="Children are welcome to attend the Nigerian wedding, however please be aware that they will also have to organise the Visa, jabs and flights for them as well as yourself."
          dutch="TBC"
          component="p"
          variant="body2"
          textAlign="justify"
        ></LanguageTypography>
      </MiniSection>

      <MiniSection>
        <LanguageTypography
          variant="h4"
          component="h4"
          english="What will the Traditional wedding be like?"
          dutch="TBC"
        ></LanguageTypography>
        <LanguageTypography
          english="We wouldn’t want to ruin the surprise… but prepare to see an amazing extravagance!"
          dutch="TBC"
          component="p"
          variant="body2"
          textAlign="justify"
        ></LanguageTypography>
      </MiniSection>
    </>
  );
}
