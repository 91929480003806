import * as React from "react";
import styled from "@emotion/styled";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  selectLanguage,
  updateLanguage,
  Language,
} from "./languageToggleSlice";

import UK from "../../public/icons/gb.png";
import Belgium from "../../public/icons/be.png";
import { useAppSelector, useAppDispatch } from "../../app/hooks";

export default function LanguageToggle() {
  const language = useAppSelector(selectLanguage);
  const dispatch = useAppDispatch();

  const ButtonsContainer = styled.div({
    /* height: "20%", */
  });

  const changeLanguage = (
    event: React.MouseEvent<HTMLElement>,
    newLanguage: Language
  ) => {
    dispatch(updateLanguage(newLanguage));
  };

  return (
    <ButtonsContainer>
      <ToggleButtonGroup
        value={language}
        exclusive
        onChange={changeLanguage}
        aria-label="text alignment"
      >
        <ToggleButton value="UK" aria-label="UK flag">
          <img src={UK} alt="UK Flag" />
        </ToggleButton>
        <ToggleButton value="Belgium" aria-label="Belgium flag">
          <img src={Belgium} alt="Belgium Flag" />
        </ToggleButton>
      </ToggleButtonGroup>
    </ButtonsContainer>
  );
}
