import LanguageTypography from "../../app/Utils/LanguageTypography";

import styled from "@emotion/styled";

const Heading = styled(LanguageTypography)(() => ({
  textAlign: "center",
  marginBottom: "2rem",
}));

const MiniSection = styled.div({
  marginTop: "1.5rem",
});

const SubHeading = styled(LanguageTypography)(() => ({
  textAlign: "left",
  marginBottom: "1rem",
}));

const BoldedTypography = styled(LanguageTypography)(() => ({
  fontWeight: 600,
}));

export default function TravelInformation() {
  return (
    <>
      <Heading
        english="Travel Information"
        dutch="TBC"
        component="h2"
        variant="h2"
        textAlign="center"
      ></Heading>
      <LanguageTypography
        variant="h3"
        component="h3"
        english="Nigerian Visa Process"
        dutch="TBC"
      ></LanguageTypography>

      <MiniSection>
        <SubHeading
          variant="h4"
          component="h4"
          english="Nigerian Visa application process"
          dutch="TBC"
        ></SubHeading>
        <LanguageTypography
          english="It is a complicated process and we would advise applying as soon as possible. Please do read through the guidance on the Nigerian Embassy website in the country you’re applying from, as the process will vary slightly depending where you’re applying from and your national passport(s). For general information please see the Nigerian immigration website at "
          dutch="TBC"
          component="p"
          variant="body2"
          textAlign="justify"
        >
          <a
            href="https://immigration.gov.ng/#visa_category"
            rel="noopener"
            target="_blank"
          >
            https://immigration.gov.ng/#visa_category
          </a>
          .
        </LanguageTypography>
      </MiniSection>

      <MiniSection>
        <SubHeading
          variant="h4"
          component="h4"
          english="Completing the online application form"
          dutch="TBC"
        ></SubHeading>
        <LanguageTypography english="" dutch="" component="p" variant="body2">
          <a
            href="https://portal.immigration.gov.ng/visa/freshVisa"
            rel="noopener"
            target="_blank"
          >
            https://immigration.gov.ng/#visa_category
          </a>
        </LanguageTypography>
        <LanguageTypography
          english="You need a google, yahoo, openID or Facebook account to create an account.
        The form asks you various things such as have you ever been to Nigeria, what you’ll be doing there, and where you will be staying etc
        You can apply for a Visitor Visa or Tourist Visa.
        "
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
      </MiniSection>

      <MiniSection>
        <LanguageTypography
          variant="h5"
          component="h5"
          english="Information you will need for the form"
          dutch="TBC"
          marginBottom="0.5rem"
        ></LanguageTypography>
        <LanguageTypography
          english="You will need a digital passport style photograph."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
        <BoldedTypography
          english="The host address in Nigeria is 65  Murtala Nyako Close, Apo, Abuja."
          dutch="TBC"
          component="p"
          variant="body2"
        ></BoldedTypography>
        <LanguageTypography
          english="The form doesn’t seem to ask for your flight details, just the dates that you are planning to arrive and leave."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
        <BoldedTypography
          english="Please be very careful when completing your form as any small typos could cause your application to be rejected or they will charge you to reprint the application."
          dutch="TBC"
          component="p"
          variant="body2"
        ></BoldedTypography>
        <BoldedTypography
          english="Please note your passport travel document needs a minimum of 6 months of validity and two blank facing pages."
          dutch="TBC"
          component="p"
          variant="body2"
        ></BoldedTypography>
      </MiniSection>

      <MiniSection>
        <SubHeading
          variant="h4"
          component="h4"
          english="Paying the Visa fee online"
          dutch="TBC"
        ></SubHeading>
        <LanguageTypography
          english="Please see Nigeria Immigration website for Visa application fees by country located at the bottom of this page: "
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
        <LanguageTypography english="" dutch="" component="p" variant="body2">
          <a
            href="https://portal.immigration.gov.ng/pages/visaguidelines"
            rel="noopener"
            target="_blank"
          >
            https://portal.immigration.gov.ng/pages/visaguidelines
          </a>
        </LanguageTypography>
        <LanguageTypography
          english="(Note: Nigeria operates a reciprocity policy so the fees are based on the visa price for a Nigerian to visit the country)"
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
        <LanguageTypography
          english="The first and last name on the application needs to be the same as the name on the payment credit card for fraud prevention."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
        <LanguageTypography
          english="You will get a receipt to say you have paid the fee."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
        <LanguageTypography
          english="Ignore the appointment you get when you make the payment, you’ll need to book an appointment separately. "
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
      </MiniSection>

      <MiniSection>
        <SubHeading
          variant="h4"
          component="h4"
          english="Print the form and payment receipt "
          dutch="TBC"
        ></SubHeading>
        <LanguageTypography
          english="A4 portrait, sign and date it"
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
      </MiniSection>

      <MiniSection>
        <SubHeading
          variant="h4"
          component="h4"
          english="Book an appointment"
          dutch="TBC"
        ></SubHeading>
        <LanguageTypography
          english="Via "
          dutch="TBC"
          component="p"
          variant="body2"
        >
          <a href="https://apt.oisservices.com" rel="noopener" target="_blank">
            https://apt.oisservices.com
          </a>
          .
        </LanguageTypography>
        <LanguageTypography
          english="You will need to present yourself at your local OIS office, this is the company that manages the Visa process. For those travelling from the UK there is an office in London and in Leicester."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
        <br></br>
        <LanguageTypography
          english="You will need to take:"
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>

        <ul>
          <li>
            <LanguageTypography
              english="your passport / travel document with a minimum of 6 months of validity and 2 blank facing pages"
              dutch="TBC"
              component="p"
              variant="body2"
            ></LanguageTypography>
          </li>
          <li>
            <LanguageTypography
              english="2 recent passport sized photos"
              dutch="TBC"
              component="p"
              variant="body2"
            ></LanguageTypography>
          </li>
          <li>
            <LanguageTypography
              english="A copy of your most recent bank statement with a minimum of £500 credit"
              dutch="TBC"
              component="p"
              variant="body2"
            ></LanguageTypography>
          </li>
          <li>
            <LanguageTypography
              english="Letter of invitation (we will send this once you have RSVP’d)"
              dutch="TBC"
              component="p"
              variant="body2"
            ></LanguageTypography>
          </li>
          <li>
            <LanguageTypography
              english="Printed form and payment receipt"
              dutch="TBC"
              component="p"
              variant="body2"
            ></LanguageTypography>
          </li>
          <li>
            <LanguageTypography
              english="Please see "
              dutch="TBC"
              component="p"
              variant="body2"
            >
              <a
                href="https://oisservices.com/visas-det.php?id=MTU="
                rel="noopener"
                target="_blank"
              >
                https://oisservices.com/visas-det.php?id=MTU=
              </a>
              <LanguageTypography
                english=" for more details"
                dutch="TBC"
                component="span"
                variant="body2"
              ></LanguageTypography>
            </LanguageTypography>
          </li>
        </ul>
      </MiniSection>

      <MiniSection>
        <SubHeading
          variant="h4"
          component="h4"
          english="Your Appointment"
          dutch="TBC"
        ></SubHeading>
        <LanguageTypography
          english="At your appointment they will take your biometric data, photograph and passport."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
        <LanguageTypography
          english="There is a £72 OIS service charge paid at submission in the UK."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
        <LanguageTypography
          english="They will also ask you to pay for Royal Mail 1st class delivery to courier your application and passport to the Nigerian embassy."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
      </MiniSection>

      <MiniSection>
        <SubHeading
          variant="h4"
          component="h4"
          english="Visa Processing"
          dutch="TBC"
        ></SubHeading>
        <LanguageTypography
          english="Standard processing time is approximately 2 weeks, however you can pay £70 for the express service which is approximately 1 week."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
        <LanguageTypography
          english="You can pay for your passport to be returned by post or you can pick it up from the office."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
        <LanguageTypography
          english="The VISA is valid for 90 days from entry."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
        <LanguageTypography
          english="Please do not hesitate to ask us if you have any questions."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
      </MiniSection>

      <LanguageTypography
        variant="h3"
        component="h3"
        english="Flights"
        dutch="TBC"
        marginTop="1rem"
        marginBottom="1rem"
      ></LanguageTypography>
      <LanguageTypography
        english="From UK you can a direct flight from Heathrow to Abuja with British Airways."
        dutch="TBC"
        component="p"
        variant="body2"
      ></LanguageTypography>
      <LanguageTypography
        english="There are no direct flights from Belgium to Nigeria. We recommend that you book a connecting flight with British Airways or Lufthansa to Abuja."
        dutch="TBC"
        component="p"
        variant="body2"
      ></LanguageTypography>
      <LanguageTypography
        english="We recommend that you book flights at least 3 months in advance. Flights tend to be cheaper the earlier you book them."
        dutch="TBC"
        component="p"
        variant="body2"
      ></LanguageTypography>
    </>
  );
}
