import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LanguageTypography from "../../app/Utils/LanguageTypography";
import styled from "@emotion/styled";
import { color } from "@mui/system";

type TimeLeft = {
  days: number | undefined;
  hours: number | undefined;
  minutes: number | undefined;
  seconds: number | undefined;
};

export default function Countdown() {
  const calculateTimeLeft = () => {
    const difference = +new Date(`2022-10-22`) - +new Date();
    let timeLeft: TimeLeft = {
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents: JSX.Element[] = [];

  Object.keys(timeLeft).forEach((interval: string) => {
    if (!timeLeft[interval as keyof TimeLeft]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval as keyof TimeLeft]} {interval}{" "}
      </span>
    );
  });

  const CountdownContainer = styled.div(({ theme }) => ({
    border: "1px solid",
    textAlign: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
    backgroundColor: "#025722",
    color: "#fff",
  }));

  return (
    <CountdownContainer>
      <LanguageTypography
        english="Time until the Big Day:"
        dutch="TBC"
        variant="h4"
        component="h4"
      ></LanguageTypography>
      <Typography>
        {timerComponents.length ? timerComponents : <span>Time's up!</span>}
      </Typography>
    </CountdownContainer>
  );
}
