import Countdown from "../Countdown/Countdown";
import LanguageTypography, {
  LanguageTypographyProps,
} from "../../app/Utils/LanguageTypography";

import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const Heading = styled(LanguageTypography)(
  (props: LanguageTypographyProps) => ({})
);

const ButtonContainer = styled.div({
  justifyContent: "center",
  width: "100%",
  display: "flex",
  marginTop: "2rem",
});

const ContentContainer = styled.div({
  textAlign: "center",
});

const RsvpButton = styled(Button)(() => ({
  fontSize: "1.3rem",
}));

const NavLink = styled(Link)(() => ({
  textDecoration: "none",
}));

export default function LandingPage() {
  return (
    <ContentContainer>
      <Heading
        english="Traditional (Nigerian) Wedding"
        dutch="TBC"
        component="h2"
        variant="h2"
      ></Heading>
      <LanguageTypography
        variant="h3"
        component="h3"
        english="Saturday 22nd October"
        dutch="TBC"
      ></LanguageTypography>
      <LanguageTypography
        variant="h3"
        component="h3"
        english="Abuja, Nigeria"
        dutch="TBC"
      ></LanguageTypography>

      <Countdown />
      <LanguageTypography
        english="We are delighted to invite you to celebrate with us in Nigeria!"
        dutch="TBC"
        component="p"
        variant="body1"
      ></LanguageTypography>

      <ButtonContainer>
        <NavLink to="rsvp">
          <RsvpButton variant="contained" size="large">
            RSVP
          </RsvpButton>
        </NavLink>
      </ButtonContainer>
    </ContentContainer>
  );
}
