import { createTheme } from "@mui/material/styles";
import "@fontsource/playfair-display";

// Set the base theme and default styling for components
const theme = createTheme({
  palette: {
    primary: {
      main: "#025722",
      dark: "#008000",
    },
    secondary: {
      main: "#fff",
    },
  },

  typography: {
    fontFamily: "'Playfair Display', serif;", //, "Helvetica", "Arial", sans-serif
    button: {
      textTransform: "none",
    },
    body1: {
      fontSize: "2rem",
    },
    body2: {
      fontSize: "1.25rem",
    },
  },

  // Set the component styles
  /*   components: {
    MuiFormControl: {
      styleOverrides: {
        root:{
          margin: '1rem 1rem 0.25rem 1rem'
        }
      }
    }
  } */
});

export default theme;
