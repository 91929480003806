import Header from "./features/Header/Header";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";
import LandingPage from "./features/LandingPage/LandingPage";
import Branch from "./public/images/branch4.png";
import Branch2 from "./public/images/branch5.png";
import styled from "@emotion/styled";
import { Route, Routes } from "react-router-dom";
import TravelInformation from "./features/TravelInformation/TravelInformation";
import Schedule from "./features/Schedule/Schedule";
import RSVP from "./features/RSVP/RSVP";
import QA from "./features/QA/QA";

const Content = styled.div({
  width: "60%",
  marginBottom: "3rem",
});

const Container = styled.div({
  backgroundImage: `url(${Branch}), url(${Branch2})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "18%",
  backgroundPosition: "left top, right bottom",
  marginTop: "3rem",
  minHeight: "78vh",
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container>
        <Content>
          {/*    <header className="App-header"></header> */}

          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="travel" element={<TravelInformation />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="rsvp" element={<RSVP />} />
            <Route path="qa" element={<QA />} />
          </Routes>
        </Content>
      </Container>
    </ThemeProvider>
  );
}

export default App;
