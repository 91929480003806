import LanguageTypography from "../../app/Utils/LanguageTypography";

import styled from "@emotion/styled";

const Heading = styled(LanguageTypography)(() => ({
  textAlign: "center",
  marginBottom: "2rem",
}));

const ContentContainer = styled.div({
  textAlign: "left",
  marginLeft: "20%",
});

export default function Schedule() {
  return (
    <>
      <Heading
        english="Schedule"
        dutch="TBC"
        component="h2"
        variant="h2"
      ></Heading>

      <ContentContainer>
        <LanguageTypography
          english="Wed-Fri 19th-21st: Guests arrive in Nigeria"
          dutch="TBC"
          component="p"
          variant="body1"
        ></LanguageTypography>
        <LanguageTypography
          english="Fri 21st: Fitting for Nigerian Costumes"
          dutch="TBC"
          component="p"
          variant="body1"
        ></LanguageTypography>
        <LanguageTypography
          english="Sat 22nd: The big day! More details to follow"
          dutch="TBC"
          component="p"
          variant="body1"
        ></LanguageTypography>
        <LanguageTypography
          english="Sun-Mon 23rd-24th: Guests depart"
          dutch="TBC"
          component="p"
          variant="body1"
        ></LanguageTypography>
      </ContentContainer>
    </>
  );
}
