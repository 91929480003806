import { selectLanguage } from "../../features/LanguageToggle/languageToggleSlice";

import { useAppSelector } from "../hooks";
import { Typography, TypographyProps } from "@mui/material";
import { ElementType } from "react";

export interface LanguageTypographyProps extends TypographyProps {
  english: string;
  dutch: string;
  component?: ElementType<any>;
}

export default function LanguageTypography(props: LanguageTypographyProps) {
  const language = useAppSelector(selectLanguage);

  return (
    <Typography {...props}>
      {language === "UK" ? props.english : props.dutch}
      {props.children}
    </Typography>
  );
}
