import LanguageTypography from "../../app/Utils/LanguageTypography";

import styled from "@emotion/styled";

const Heading = styled(LanguageTypography)(() => ({
  textAlign: "center",
  marginBottom: "2rem",
}));

const ContentContainer = styled.div({
  textAlign: "left",
  marginLeft: "20%",
});

export default function RSVP() {
  return (
    <>
      <Heading english="RSVP" dutch="TBC" component="h2" variant="h2"></Heading>

      <ContentContainer>
        <LanguageTypography
          english="We understand that not everyone can make it to the traditional celebration of our marriage, so we will also be hosting a wedding in Belgium. Please RSVP here if you can make the traditional wedding."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
        <LanguageTypography
          english="Please RSVP for yourself and separately for your +1 if your invite includes a plus one."
          dutch="TBC"
          component="p"
          variant="body2"
        ></LanguageTypography>
      </ContentContainer>
    </>
  );
}
